export default [
  {
    fileName: "client/up-cadou-paste.png",
    link: "https://upromania.ro/companii/card-cadou/",
    category: "Banner",
    action: "Click",
    label: "Client_card_cadou",
  },
  {
    fileName: "client/up-onlline-clienti.png",
    link:
      "https://upromania.ro/companii/card-cultural/",
    category: "Banner",
    action: "Click",
    label: "campanie_card_cultura",
  },
];
